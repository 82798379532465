import styled , {css}from "styled-components"
import { useEffect, useRef, useState } from "react"
export const Skills = () =>{


    const list = ["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL", "MongoDB", "EJS","Express", "Node.JS"]

    const [isVisible, setIsVisible] = useState(false) // initial state is false
//Step 2
// add a reference to the section we're focusing. useRef is a hook
const skillRef = useRef(null)

//Step 3
// Add useEffect to add and remove when the component mounts and unmounts


useEffect(() => {
    const handleScroll = () => {
      const section = skillRef.current;

      if (section) {
        const { top, bottom } = section.getBoundingClientRect();
        const isVisible = top < window.innerHeight && bottom >= 0;
        setIsVisible(isVisible);
        // console.log(section.getBoundingClientRect())
        // console.log("inner heighr", window.innerHeight)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
return (
    <>
<Container ref={skillRef}>
    <H1>My skills</H1>
    <SkillContainer>
{list.map((item, key)=>(
 
<Button isVisible={isVisible} key={key}>{item}</Button>

))}
</SkillContainer>
</Container>

    </>
)

}

const Container = styled.div`
display: flex;
width: 100%;
flex-direction: column;
max-width: 500px;
@media (max-width: 700px) {

 margin-left: 10px;
  }


`

const SkillContainer = styled.div`
display: flex;
flex-wrap: wrap;
@media (max-width: 700px) {
 width: 140px;

  }
`

const Button = styled.div`
height: 40px;
background-color: white;
border-radius: 10px;
padding: 10px;
margin: 0px 10px 10px 0px;
color: #FF5F1F;
font-weight:700;
opacity: 0;
animation: toRight 2s ease-in-out forwards;
${({ isVisible }) =>
    isVisible &&
    css`
@keyframes toRight {
    0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  } 
    `} 


`

const H1 = styled.h1`
font-size: 30px;
margin-bottom:10px;
`