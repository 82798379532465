import styled from "styled-components"
import cv from "./assets/thajanahm.pdf"

export const Download = () =>{
return (
    <div>
        <Button href={cv} >Download CV</Button>
    </div>
)

}

const Button = styled.a`
display: inline-block;
padding: 12px 28px;
background-color:#FF5F1F ;
color: white;
border-radius:  40px;
box-shadow: 0 0 10px #FF5F1F;
font-size: 16px;
letter-spacing: 1px;
font-weight: 600;
text-decoration: none;
`