import styled , {css}from "styled-components"
import {About} from "./About"
import { Skills } from "./Skills"
import { useEffect, useRef, useState } from 'react';


const AboutPage =
({aboutRef})=>{


//animation effect on the ABOUT ME title
//Step 1
// to achieve this I need a useState on my AboutContainer, so when the state
//changed it triggers the effect. State only changes when I've scrolled into the 
//section.
const [isVisible, setIsVisible] = useState(false) // initial state is false
//Step 2
// add a reference to the section we're focusing. useRef is a hook
const aboutMeRef = useRef(null)

//Step 3
// Add useEffect to add and remove when the component mounts and unmounts


useEffect(() => {
    const handleScroll = () => {
      const section = aboutMeRef.current;

      if (section) {
        const { top, bottom } = section.getBoundingClientRect();
        const isVisible = top < window.innerHeight && bottom >= 0;
        setIsVisible(isVisible);
        // console.log(section.getBoundingClientRect())
        // console.log("inner heighr", window.innerHeight)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
return(
  <div ref={aboutRef} style={{width:"100%"}}> 
    <AboutContainer ref={aboutMeRef}    >

    <SectionTitle isVisible={isVisible} >ABOUT ME</SectionTitle>
    <Underline> </Underline>
    <p>Here you will find more information about me, what I do, and my current skills mostly in terms of programming and technology</p>

    <ContentWrapper>
<About/>
<Skills/>
    </ContentWrapper>
    </AboutContainer>
    </div>
)
}
export default AboutPage
const AboutContainer = styled.div`
 box-sizing: border-box;
background-color: #FF5F1F;
width: 100vw;
color:white;
display: flex;
flex-direction: column;
height:100%;
position: relative;
align-items: center;
padding: 0px 15px 30px 15px;



`

const SectionTitle = styled.h1`
margin-top: 50px;
display: flex;
justify-content: center;
letter-spacing: 1mm;
font-weight: 700;
font-size: 40PX;
 opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.7s ease;

  /* Add additional styles as needed */
  
   ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: translateY(0);
    `} 

`

const ContentWrapper = styled.div`
 width: 100%;
  height: 100%;
display: flex;
justify-content: space-between;
padding: 70px 10% 0;
@media (max-width: 700px) {
  padding: 20px;
  }




`

const Underline = styled.button`
border: none;
background-color: white ;
width: 20px;
height: 4px;
margin: 15px 0 15px 0;
border-radius: 10px;
`