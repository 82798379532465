import menu from "./assets/menu.png";
import styled from "styled-components";
import { useState } from "react";

export const HamburgerMenu = ({ scrollToHome, scrollToProjects, scrollToAbout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Wrapper>
      <Img src={menu} onClick={toggleMenu} alt="" />

      {isMenuOpen && (
        <MenuDiv>
          <ListItem onClick={() => { scrollToHome(); setIsMenuOpen(false); }}>Home</ListItem>
          <ListItem onClick={() => { scrollToAbout(); setIsMenuOpen(false); }}>About</ListItem>
          <ListItem onClick={() => { scrollToProjects(); setIsMenuOpen(false); }}>Projects</ListItem>
        </MenuDiv>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: block; /* Show the component by default on all screens */

  @media (min-width: 799px) {
    display: none; /* Hide the component only on screens wider than 768px */
  }
`;

const ListItem = styled.button`
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 40px;
  height: 270px;
  width: 100vw;
  padding: 10px;
  border: none;
  :hover {
    background-color: white;
    color: #ff5f1f;
    cursor: pointer;
    font-weight: 700;
  }
`;

const MenuDiv = styled.div`
  position: fixed;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
`;

const Img = styled.img`
  position: fixed;
  right:29.9px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
`;
