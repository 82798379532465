
import React from "react"
import Navbar from "./Navbar"
import  Home  from "./Home"
import AboutPage from "./AboutSection/AboutPage"
import ProjectPg from "./ProjectSection/ProjectPg"
import { useRef } from "react";
import { HamburgerMenu } from "./HamburgerMenu";


function App() {
  

  const projectsRef = useRef(null)
  const aboutRef = useRef(null)
const homeRef = useRef(null)

  const scrollToProjects = () => {
    projectsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };



  
  return (
  <>
  <Navbar scrollToHome={scrollToHome} homeRef={homeRef} scrollToProjects={scrollToProjects} scrollToAbout={scrollToAbout}/>
  <HamburgerMenu scrollToHome={scrollToHome} scrollToProjects={scrollToProjects} scrollToAbout={scrollToAbout} />
  <Home  />
  <AboutPage aboutRef={aboutRef} />
<ProjectPg projectsRef={projectsRef} />

  </>


  );
}

export default App;
