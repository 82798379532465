import React, { useState } from "react";
import styled, { keyframes } from "styled-components";






const Navbar = ({scrollToProjects, scrollToAbout, homeRef, scrollToHome}) => {
  // this usestate is for the hamburger menu


  return (
    <>
      <HeaderStyle>
        <ProfolioLogo ref={homeRef} href="">Profolio.</ProfolioLogo>
        <NavbarStyled>
          <LinkStyle  onClick={scrollToHome} index={1} >
            Home
          </LinkStyle>
          <LinkStyle  onClick={scrollToAbout} index={2}>
            About
          </LinkStyle>
          <LinkStyle  onClick={scrollToProjects} index={3}>
            Portfolio
          </LinkStyle>
          {/* <LinkStyle  index={4}>
            Contact
          </LinkStyle> */}
        </NavbarStyled>
      </HeaderStyle>
    </>
  );
};

export default Navbar;

const NavbarStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 52px;
  top:5px;
  z-index: 9999;
  /* justify-content: center;
  align-items: center; */

  @media (max-width: 900px ){
    display: none;
  }
`;

const HeaderStyle = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
`;


const slideRightAnimation = keyframes`
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideTopAnimation = keyframes`
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const LinkStyle = styled.a`
  font-size: 18px;
  color: #000000;
  text-decoration: none;
  font-weight: 700;
  margin-left: 36px;
  transition: 0.9s;
  animation: ${slideTopAnimation} 0.5s ease forwards;
  animation-delay: calc(0.2s * ${({ index }) => index});

  &:hover {
    color: #ff5f1f;
    cursor: pointer;
  }

  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  &:nth-child(4) {
    animation-delay: 0.8s;
  }

  &.active {
    color: #ff5f1f;
  }
`;

const ProfolioLogoAnimation = keyframes`
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ProfolioLogo = styled.a`
  font-size: 25px;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  cursor: default;
  opacity: 0;
  animation: ${slideRightAnimation} 1s ease forwards;
  @media (min-width: 768px) {
    animation: ${ProfolioLogoAnimation} 1s ease forwards;

  }
`;
