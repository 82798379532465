import linkedIn from "./assets/linkedIn.svg";
import github from "./assets/github.svg";
import styled from "styled-components";
import youtube from "./assets/youtube.svg";
import React, { useState } from "react";

export const Socials = () => {
  const [isLinkedInHovered, setIsLinkedInHovered] = useState(false);
  const [isGithubHovered, setIsGithubHovered] = useState(false);
  const [isYoutubeHovered, setIsYoutubeHovered] = useState(false);

  const handleLinkedInMouseEnter = () => {
    setIsLinkedInHovered(true);
  };

  const handleLinkedInMouseLeave = () => {
    setIsLinkedInHovered(false);
  };

  const handleGithubMouseEnter = () => {
    setIsGithubHovered(true);
  };

  const handleGithubMouseLeave = () => {
    setIsGithubHovered(false);
  };

  const handleYoutubeMouseEnter = () => {
    setIsYoutubeHovered(true);
  };

  const handleYoutubeMouseLeave = () => {
    setIsYoutubeHovered(false);
  };

  return (
    <>
    <LinkStyle href="https://github.com/TeeCreates">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{
          fill: isLinkedInHovered ? "#FFFFFF" : "#FF5F1F",
          transition: "fill 0.3s",
        }}
        onMouseEnter={handleLinkedInMouseEnter}
        onMouseLeave={handleLinkedInMouseLeave}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974 0 4.406 2.857 8.145 6.821 9.465.499.09.679-.217.679-.481 0-.237-.008-.865-.011-1.696-2.775.602-3.361-1.338-3.361-1.338-.452-1.152-1.107-1.459-1.107-1.459-.905-.619.069-.605.069-.605 1.002.07 1.527 1.028 1.527 1.028.89 1.524 2.336 1.084 2.902.829.091-.645.351-1.085.635-1.334-2.214-.251-4.542-1.107-4.542-4.93 0-1.087.389-1.979 1.024-2.675-.101-.253-.446-1.268.099-2.64 0 0 .837-.269 2.742 1.021a9.582 9.582 0 0 1 2.496-.336 9.554 9.554 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021.545 1.372.203 2.387.099 2.64.64.696 1.024 1.587 1.024 2.675 0 3.833-2.33 4.675-4.552 4.922.355.308.675.916.675 1.846 0 1.334-.012 2.41-.012 2.737 0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974 22 6.465 17.535 2 12.026 2z"
        ></path>
      </svg>
    
      </LinkStyle>
      <LinkStyle href="https://www.linkedin.com/in/thajanah-mailvaganam/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{
          fill: isGithubHovered ? "#FFFFFF" : "#FF5F1F",
          transition: "fill 0.3s",
        }}
        onMouseEnter={handleGithubMouseEnter}
        onMouseLeave={handleGithubMouseLeave}
      >
        <circle cx="4.983" cy="5.009" r="2.188"></circle>
        <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
      </svg>
      </LinkStyle>
      <LinkStyle href="https://www.youtube.com/@TM-fn8uu/videos">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{
          fill: isYoutubeHovered ?"#FFFFFF" : "#FF5F1F",
          transition: "fill 0.3s",
        }}
        onMouseEnter={handleYoutubeMouseEnter}
        onMouseLeave={handleYoutubeMouseLeave}
      >
        <path d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path>
      </svg>
      </LinkStyle>
    </>
  );
};

const LinkStyle = styled.a`

display:inline-flex;
justify-content: center
;
align-items: center;
width: 40px;
height: 40px;
background-color: transparent;
border: 2px solid #FF5F1F;
border-radius: 50%;
margin: 30px 15px 30px 0;

:hover{
    background-color:#FF5F1F ;
   box-shadow: 0 0 20px #FF5F1F ;
  
}
`