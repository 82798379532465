import data from "./projects.json"
import styled from "styled-components"
import laptop1 from "../assets/laptop1.png"
import laptop2 from "../assets/laptop2.png"
import laptop3 from "../assets/laptop3.png"
import laptop4 from "../assets/laptop4.png"
const ProjectPg = ({projectsRef}) =>{

  const projectTools1 = ["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL", "MongoDB","Express", "Node.JS"]

  const firstPortfolioTools = ["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL","STYLED COMPONENTS"]

  const projectObj =[{
    project1:{
      title:"Ishine",
      description:"In the case of Ishine, a real-world business, there was a notable absence of an online platform to showcase its services and facilitate communication. To address this need, I developed a mobile-friendly website for Ishine, providing comprehensive service details and a user-friendly contact form, including phone number and social media links. Additionally, the website features an English-to-French translation option to cater to a wider audience.",
      tools:["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL", "EMAILJS","STYLED COMPONENTS"],
      img:laptop4
    },
    project2:{
      title:"E-Wears",
      description:"E-Wears is a e-commerce site that showcases wearable tech gear products. This was a collaborative team project.",
      tools:["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL", "MongoDB","Express", "Node.JS"],
      img:laptop1
    },
    project3:{
      title:"Sacred Drapes",
      description:"Online site to book a saree draper for special North and South Indian events.",
      tools:["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL", "MongoDB","Express", "Node.JS"],
      img:laptop2
    },
    project4:{
      title:"My First Portfoli",
      description:"It's a personal frontend project I created to display my work.",
      tools:["HTML", "CSS", "JAVASCRIPT", "React", "GIT", "GITHUB", "TERMINAL", "Node.JS"],
      img:laptop3
    }
  }]




return(
    <Wrapper ref={projectsRef}>
<Title>PROJECTS</Title>
<Underline> </Underline>
<P>Here you'll find some personal projects and school projects that I created</P>
{/* PROJECT 1 */}
<ProjectDiv className="first">
  <LaptopImg src={projectObj[0].project1.img} alt="" />
  <DescriptionDIV className="firstDes">
    <H2>{projectObj[0].project1.title}</H2>
    <Pdes>{projectObj[0].project1.description}</Pdes>
    <H3>Tools Used</H3>
 <ToolsDiv>
 {projectObj[0].project1.tools.map((item, key)=>(

  <Tools key={key}>{item}</Tools>
))}
 </ToolsDiv>
    <LinkStyle href="https://www.ishinemtl.ca/">Link</LinkStyle>
  </DescriptionDIV>
</ProjectDiv>
{/* PROJECT 2 */}
{/* Project 3 */}
<ProjectDiv>
  <LaptopImg src={projectObj[0].project2.img} alt="" />
  <DescriptionDIV>
    <H2>{projectObj[0].project2.title}</H2>
    <Pdes> {projectObj[0].project2.description} </Pdes>
    <H3>Tools Used</H3>
 <ToolsDiv>
    {projectObj[0].project2.tools.map((item, key)=>(
 
 <Tools key={key}>{item}</Tools>
 
 ))}
 </ToolsDiv>
    <LinkStyle href="https://thajanahprofolio.netlify.app/">Link</LinkStyle>
  </DescriptionDIV>
</ProjectDiv>
{/* Project 2 */}
<ProjectDiv>
  <LaptopImg src={laptop2} alt="" />
  <DescriptionDIV>
    <H2>Sacred Drapes</H2>
    <Pdes>Online site to book a saree draper for special North and South Indian events. </Pdes>
    <H3>Tools Used</H3>
 <ToolsDiv>
    {projectTools1.map((item, key)=>(
 
 <Tools key={key}>{item}</Tools>
 
 ))}
 </ToolsDiv>
    <LinkStyle href="https://www.youtube.com/watch?v=JtogNM2BRdo&t=8s">Link</LinkStyle>
  </DescriptionDIV>
</ProjectDiv>

{/* Project 4 */}
<ProjectDiv>
  <LaptopImg src={laptop3} alt="" />
  <DescriptionDIV>
    <H2>My First Portfolio</H2>
    <Pdes> It's a personal frontend project I created to display my work. </Pdes>
    <H3>Tools Used</H3>
 <ToolsDiv>
    {firstPortfolioTools.map((item, key)=>(
 
 <Tools key={key}>{item}</Tools>
 
 ))}
 </ToolsDiv>
    <LinkStyle href="https://thajanahprofolio.netlify.app/">Link</LinkStyle>
  </DescriptionDIV>
</ProjectDiv>


{/* {projectObj.forEach((project) => {
  const projectKey = Object.keys(project)[0];
  console.log(projectKey, "kkkkkey")
  const projectData = project[projectKey];
console.log(projectData.title, "ddd")
 
return (
<ProjectDiv>
  <LaptopImg src={projectData.img} alt="" />
  <div>
    <H2>My First Portfolio</H2>
    <Pdes> It's a personal frontend project I created to display my work. </Pdes>
    <H3>Tools Used</H3>
 <ToolsDiv>
    {firstPortfolioTools.map((item, key)=>(
 
 <Tools key={key}>{item}</Tools>
 
 ))}
 </ToolsDiv>
    <LinkStyle href="https://thajanahprofolio.netlify.app/">Link</LinkStyle>
  </div>
</ProjectDiv>
)
 } )} */}



    </Wrapper>


)
}
export default ProjectPg

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
height: 100%;
padding: 20px;
`


const Underline = styled.button`
border: none;
background-color: #FF5F1F ;
width: 20px;
height: 4px;
margin: 15px 0 15px 0;
border-radius: 10px;
`

const Title = styled.h1`
color: black ;
font-size: 40px;
font-weight: 700;
letter-spacing: 0.5mm;
` 

const P = styled.p`
color: #232323;
`

const ProjectDiv = styled.div`
display: flex;
align-items: center;
max-width: 1100px;

  margin-bottom: 30px;
  
@media (max-width: 1000px) {
  display: flex;
  flex-direction: column;
  
  max-width: 380px;
  /* background-color: darkblue; */
  }
`

const LaptopImg = styled.img`
width: 600px;
@media (max-width: 800px) {
width:300px ;
  }
`
const LinkStyle = styled.a`
display: inline-block;
padding: 12px 28px;
background-color:#FF5F1F ;
color: white;
border-radius: 10px;
box-shadow: -1px 10px 30px -9px rgba(0,0,0,0.17);
-webkit-box-shadow: -1px 10px 30px -9px rgba(0,0,0,0.17);
-moz-box-shadow: -1px 10px 30px -9px rgba(0,0,0,0.17);
font-size: 16px;
letter-spacing: 1px;
font-weight: 600;
text-decoration: none;
border: none;
cursor: pointer;
margin-top: 10px;
:hover{
  scale:1.02;
  transform: translateY(-2px);
}

@media (max-width: 700px) {
margin: 0px 10px 0px 10px;
/* padding: 30px; */
  }
`

const H2 = styled.h2`
font-weight: 700;
font-size: 24px;
@media (max-width: 800px) {
  margin: 0px 10px 0px 10px;
  /* background-color: darkblue; */
  }

`

const Tools = styled.div`
height: 40px;
background-color: #E8E8E8;
border-radius: 10px;
padding: 10px;
margin: 0px 10px 10px 0px;
color: #898989;
font-weight:700;

`

const ToolsDiv = styled.div `
display: flex;
flex-wrap: wrap;
max-width: 500px;

@media (max-width: 700px) {
margin: 0px 10px 0px 10px;
/* padding: 30px; */
  }

`

const H3 = styled.h3`
font-size: 20px;
font-weight: 700;
margin-top: 10px;
margin-bottom: 3px;

@media (max-width: 700px) {
margin: 10px 10px 0px 10px;
/* padding: 30px; */
  }

`

const Pdes = styled.p`

@media (max-width: 700px) {
width:100% ;
margin: 0px 10px 0px 10px;
/* padding: 30px; */
  }
`

const DescriptionDIV = styled.div`

&.firstDes {
 position: relative;
 top: 43px;
  }
@media (max-width: 700px) {
  padding: 20px;
  &.firstDes {
 position: static;
 top: auto;
  }
  }

`