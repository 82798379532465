import styled from "styled-components"
import {ContactMeBtn} from "./ContactMeBtn"
export const About =() =>{

    return(

        <>
        <Wrapper>
        <H3>Get to know me!</H3>
        <Text>
            I'm a <Emp>Full-stack Web Developer</Emp> building both <Emp>Frontend responsive design</Emp> and <Emp>Backend data bases</Emp>. I completed a bootcamp in <Emp>Web Development</Emp> at Concordia University.
        </Text>

        <Text>
        I'm open to <Emp>Job</Emp> opportunities where I can contribute, learn and grow. If you have a good opportunity that matches my skills and experience then don't hesitate to contact me.
        </Text>
        {/* <ContactMeBtn/> */}
        </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
max-width: 500px;


`
const H3 = styled.h3`
font-size: 30px;
margin-bottom:10px;
`

const Text = styled.p`
font-size: 16px;
font-weight: 100;
margin-bottom: 10px;
`
const Emp = styled.span`
font-weight: 700;

`