import styled from "styled-components"
export const ContactMeBtn = ()=>{


    return(

        <>
      <Button>Contact Me</Button>
        </>
    )
}

const Button = styled.a`
display: inline-block;
padding: 12px 28px;
background-color:white ;
color: #FF5F1F;
border-radius: 10px;
box-shadow: 0 0 3px white;
font-size: 16px;
letter-spacing: 1px;
font-weight: 600;
text-decoration: none;
border: none;
cursor: pointer;
margin-top: 30px;
:hover{
  scale:1.02;
  transform: translateY(-2px);
}
`